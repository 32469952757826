<div
  *ngIf="isBusinessOfferEnabled"
  class="text-darkslateblue mt-4 flex flex-col items-center justify-start gap-[8px] self-stretch pb-2 text-xs"
  data-clarity-unmask="true"
>
  <div
    *ngIf="offerVisible === 1"
    class="relative flex cursor-pointer flex-col items-center justify-start gap-[1px] self-stretch"
    id="business-plan-offer-1"
    (click)="goToOffer(1)"
  >
    <b
      class="text-darkslateblue relative z-[0] self-stretch text-center uppercase tracking-[0.5em]"
      >{{ offerTitle }}</b
    >
    <div
      class="z-[1] flex flex-row items-center justify-between self-stretch rounded py-2.5 pl-[30px] pr-4 text-left text-base text-white [background:radial-gradient(circle_at_top_left,_rgba(139,_227,_255,_0.7),_rgba(61,_123,_199,_0.25),_rgba(61,_123,_199,_0)),_linear-gradient(180deg,_#2f6cc3,_#22518e),_rgba(47,_108,_195,_0.5)]"
    >
      <div class="flex flex-row items-start justify-center">
        <div class="flex flex-col items-start justify-start">
          <b class="relative">Annual Premium Plan</b>
          <div class="relative text-sm">
            <b>1 </b>
            <span>year premium</span>
          </div>
        </div>
      </div>
      <b class="relative text-3xl">$790</b>
    </div>
    <img
      class="absolute bottom-[50.65%] left-[2.06%] right-[87.9%] top-[2.6%] z-[2] mx-[!important] my-0 h-[46.75%] max-h-full w-[10.03%] max-w-full overflow-hidden"
      alt=""
      src="assets/icons/sparkles.svg"
    />
  </div>
  <div
    *ngIf="offerVisible === 2"
    class="relative flex cursor-pointer flex-col items-center justify-start gap-[1px] self-stretch"
    id="business-plan-offer-2"
    (click)="goToOffer(2)"
  >
    <b
      class="text-darkslateblue relative z-[0] self-stretch text-center uppercase tracking-[0.5em]"
      >{{ offerTitle }}</b
    >
    <div
      class="bg-cornflowerblue z-[1] flex flex-row items-center justify-between self-stretch rounded py-2.5 pl-[30px] pr-4 text-left text-base text-white"
    >
      <div class="flex flex-row items-start justify-center">
        <div class="flex flex-col items-start justify-start">
          <b class="relative">Annual Premium Plan</b>
          <div class="relative text-sm">
            <b>1 </b>
            <span>year premium</span>
          </div>
        </div>
      </div>
      <b class="relative text-3xl">$790</b>
    </div>
    <img
      class="absolute bottom-[50.65%] left-[2.06%] right-[87.9%] top-[2.6%] z-[2] mx-[!important] my-0 h-[46.75%] max-h-full w-[10.03%] max-w-full overflow-hidden"
      alt=""
      src="assets/icons/sparkles1.svg"
    />
  </div>
  <div
    *ngIf="offerVisible === 3"
    class="flex cursor-pointer flex-col items-center justify-start gap-[1px] self-stretch"
    id="business-plan-offer-3"
    (click)="goToOffer(3)"
  >
    <b
      class="text-darkslateblue relative self-stretch text-center uppercase tracking-[0.5em]"
      >{{ offerTitle }}</b
    >
    <div
      class="bg-cornflowerblue text-gainsboro flex flex-col items-start justify-start self-stretch rounded px-3 py-2.5 text-left text-base"
    >
      <div class="flex w-full flex-row items-start justify-between">
        <div class="flex flex-row items-start justify-center gap-[8px]">
          <img
            class="relative h-9 w-[29.2px]"
            alt=""
            src="assets/icons/sparkles2.svg"
          />

          <div class="flex flex-col items-start justify-start">
            <b class="relative text-gray-300">Annual Premium Plan</b>
            <div class="relative text-sm text-white">20% off Premium Plan</div>
          </div>
        </div>
        <b class="relative text-3xl text-white">$294</b>
      </div>
    </div>
  </div>

  <fluent-progress-ring
    *ngIf="loading$ | async"
    class="block w-4"
  ></fluent-progress-ring>
</div>
