import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
  BillingService,
  CheckoutRequestPlan,
  ResolvePurchaseResponseDto,
} from '@gentext/api-psychology-client';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReplaySubject } from 'rxjs';
import { LicenseService } from './license.service';

type SuccessOfficeDialogArgs = {
  message: string;
  origin: string | undefined;
};

type ErrorOfficeDialogArgs = {
  error: number;
};
export const MANAGE_PLAN = 'managePlan';
export const CHECKOUT_SESSION = 'checkoutSession';
type OfficeDialogArgs = SuccessOfficeDialogArgs | ErrorOfficeDialogArgs;
@Injectable({ providedIn: 'root' })
export class BillingDialogService {
  private _loading$ = new ReplaySubject<boolean>();
  private _hasBadRequestError$ = new ReplaySubject<boolean>();
  private _hasUnexpectedError$ = new ReplaySubject<boolean>();
  private _resolveResponse$ = new ReplaySubject<
    ResolvePurchaseResponseDto | undefined
  >();

  resolveResponse$ = this._resolveResponse$.asObservable();
  hasBadRequestError$ = this._hasBadRequestError$.asObservable();
  hasUnexpectedError$ = this._hasUnexpectedError$.asObservable();
  loading$ = this._loading$.asObservable();

  showManagePlanDialog() {
    this._loading$.next(true);
    this.billingService
      .billingGetPortalUrlPost({
        returnUrl: `${window.location.origin}/billingredirect/${MANAGE_PLAN}`,
      })
      .subscribe({
        next: (res) => {
          const dialogUrl = `${
            window.location.origin
          }/redirect?url=${encodeURIComponent(res.url)}`;
          this.showDialog(dialogUrl);
        },
        error: (err) => {
          const dialogUrl = `${
            window.location.origin
          }/redirect?url=${encodeURIComponent(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            process.env['NX_MENTALNOTE_STRIPE_CUSTOMER_PORTAL_URL']!,
          )}`;
          this.showDialog(dialogUrl);
          this.logging.exception({ exception: err });
          this.emitLoading(false);
        },
      });
  }
  showUpgradeDialog(
    plan: CheckoutRequestPlan,
    couponCode: string | undefined = undefined,
    customerId: string | undefined = undefined,
  ) {
    this._loading$.next(true);
    this.billingService
      .billingCreateCheckoutSessionPost({
        plan,
        couponCode,
        webBaseUrl: `${window.location.origin}/billingredirect/${CHECKOUT_SESSION}`,
        customerId,
      })
      .subscribe({
        next: (res) => {
          const dialogUrl = `${
            window.location.origin
          }/redirect?url=${encodeURIComponent(res.url)}`;
          this.showDialog(dialogUrl);
        },
        error: (err) => {
          this._hasUnexpectedError$.next(err);
          this.logging.exception({ exception: err });
          this.emitLoading(false);
        },
      });
  }

  clearResponse() {
    this._resolveResponse$.next(undefined);
    this.emitLoading(false);
  }

  private emitLoading(loading: boolean) {
    this.zone.run(() => {
      this._loading$.next(loading);
    });
  }

  constructor(
    private billingService: BillingService,
    private logging: LoggingService,
    private licenseService: LicenseService,
    private zone: NgZone,
  ) {}

  private showDialog(dialogUrl: string) {
    let dialog: Office.Dialog;

    const processStripeDialogMessage = async (args: OfficeDialogArgs) => {
      const successArg = args as SuccessOfficeDialogArgs;
      if (successArg) {
        const messageFromDialog = JSON.parse(successArg.message);
        this.logging.trace({
          message: '[BillingDialogService] - Message from dialog',
          properties: { messageFromDialog },
        });
        if (messageFromDialog.type === CHECKOUT_SESSION) {
          let stripeToken = '';
          if (messageFromDialog.status === 'success') {
            stripeToken = messageFromDialog.stripeToken;
          }
          if (stripeToken) {
            this.resolveStripeToken(stripeToken);
          } else {
            this.logging.trace({
              message: 'Unexpected response from the Stripe dialog',
              properties: { args },
              severityLevel: SeverityLevel.Error,
            });
            this.emitLoading(false);
            this._hasUnexpectedError$.next(true);
          }
        } else if (messageFromDialog.type === MANAGE_PLAN) {
          this.logging.trace({
            message: 'Returned from manage plan dialog, reloading & closing',
          });
          await this.licenseService.getLicense();
          this.emitLoading(false);
        } else {
          this.logging.trace({
            message: 'Unexpected type in billing dialog response',
            severityLevel: SeverityLevel.Warning,
          });
          this.emitLoading(false);
        }
      }
      dialog.close();
    };
    const processStripeDialogEvent = async (args: OfficeDialogArgs) => {
      const errorArgs = args as ErrorOfficeDialogArgs;

      if (errorArgs?.error === 12006) {
        // user closed dialog
        await this.licenseService.getLicense();
        this.emitLoading(false);
      }
    };

    Office.context.ui.displayDialogAsync(
      dialogUrl,

      { height: 600, width: 800 },
      (result) => {
        this.logging.trace({
          message: 'Billing confirmation',
          properties: result,
        });

        dialog = result.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          processStripeDialogMessage,
        );

        dialog.addEventHandler(
          Office.EventType.DialogEventReceived,
          processStripeDialogEvent,
        );
      },
    );
  }

  private resolveStripeToken(stripeToken: string) {
    this._hasBadRequestError$.next(false);
    this._hasUnexpectedError$.next(false);
    this.emitLoading(true);
    this.billingService.billingResolveGet(stripeToken).subscribe({
      error: async (err) => {
        this.logging.exception(err);
        const httpError = err as HttpErrorResponse;
        if (httpError?.status === 400) {
          this._hasBadRequestError$.next(true);
        } else {
          this._hasUnexpectedError$.next(true);
        }
        await this.licenseService.getLicense();
        this.emitLoading(false);
      },
      next: async (res) => {
        this.logging.trace({
          message: `response received from billing resolve`,
          properties: {
            res,
          },
        });
        await this.licenseService.getLicense();
        this.emitLoading(false);

        this._resolveResponse$.next(res);
      },
    });
  }
}
