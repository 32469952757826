// eslint-disable-next-line @typescript-eslint/triple-slash-reference
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  fluentButton,
  fluentCard,
  fluentOption,
  fluentProgressRing,
  fluentRadio,
  fluentRadioGroup,
  fluentSelect,
  fluentTextField,
  provideFluentDesignSystem,
} from '@fluentui/web-components';

import { AppModule } from './app/app.module';

provideFluentDesignSystem().register(
  fluentCard(),
  fluentButton(),
  fluentTextField(),
  fluentProgressRing(),
  fluentSelect(),
  fluentOption(),
  fluentRadio(),
  fluentRadioGroup(),
);
enableProdMode();
// Reference: https://www.npmjs.com/package/@microsoft/office-js
if (typeof Office !== 'undefined') {
  console.log('main.ts => init');
  Office.initialize = () => {
    console.log('main.ts => Office.initialize');
    Office.onReady()
      .then(() => {
        console.log('main.ts => Office is ready');
        return bootStrapAngular();
      })
      .catch((err: unknown) => {
        console.warn('Angular not bootstrapped. Error: \n');
        console.log(err);
      });
  };
  Office.initialize(0);
} else {
  console.log('Bootstrapping Angular, without Office JS');
  bootStrapAngular();
}

function bootStrapAngular(): Promise<void | NgModuleRef<AppModule>> {
  return platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((error) => {
      console.log('Error bootstrapping Office Angular app: ', error);
    });
}
