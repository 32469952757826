<div
  class="relative flex max-h-screen min-h-screen flex-col bg-white p-1"
  id="app-main-card"
>
  <div
    class="relative my-2 flex w-full flex-row items-center justify-between bg-white px-4"
  >
    <img src="assets/icon-64.png" alt="Company Logo" class="h-8" />
    <h1 class="text-xl font-bold">MentalNote</h1>
    <fluent-button
      *ngIf="isAuthenticated"
      class="menu-class-name text-l text-black"
      appearance="lightweight"
      data-testid="dropdown-menu-button"
      (click)="toggleMenu()"
    >
      ☰ Menu
    </fluent-button>
    <div *ngIf="!isAuthenticated" class="w-10">
      <!-- &nbsp; just placeholder to keep header centered -->
    </div>
    <div
      *ngIf="menuVisible"
      class="absolute right-3 top-[40px] z-50 w-[120px] bg-white p-2 shadow-md"
    >
      <div *ngIf="license$ | async as license">
        <fluent-button
          *ngIf="showManagePlan$ | async"
          [routerLink]="['/manage-plan']"
          appearance="filled"
          class="mb-2 w-full text-xs"
          data-testid="manage-plan-button"
        >
          {{ license.planId === 'free' ? 'Upgrade' : 'Manage Plan' }}
        </fluent-button>
        <fluent-button
          appearance="filled"
          class="mb-2 w-full text-xs"
          [routerLink]="['/chat']"
        >
          Home
        </fluent-button>
      </div>

      <div>
        <fluent-button
          (click)="support()"
          appearance="filled"
          class="mb-2 w-full text-xs"
          data-testid="support-link"
        >
          Help
        </fluent-button>
        <fluent-button
          class="mb-2 w-full text-xs"
          (click)="signOut()"
          appearance="filled"
        >
          Sign out
        </fluent-button>
      </div>
      <div
        data-testid="licenseIndicator"
        class="text-xs"
        *ngIf="license$ | async as license"
      >
        License:
        {{
          license.planId === 'free'
            ? 'Free'
            : license.planId === 'premium'
              ? 'Premium'
              : license.planId === 'enterprise'
                ? 'Enterprise'
                : 'unknown'
        }}<br />Words Left:
        <span *ngIf="license.isUnlimited; else tmplWordsLeft">&infin;</span>
        <ng-template #tmplWordsLeft>
          {{ license.wordsLeft }}
        </ng-template>
      </div>
    </div>
  </div>
  <gentext-alerts-banner></gentext-alerts-banner>
  <div
    class="mb-4 mt-[160px] flex flex-col items-center px-4"
    *ngIf="isAuthenticated === false"
  >
    <fluent-button
      class="my-1 w-full"
      (click)="signIn()"
      appearance="accent"
      [disabled]="isLoadingAuth$ | async"
      >Sign In</fluent-button
    >
    <fluent-button class="my-1 w-full" (click)="support()">Help</fluent-button>
    <div
      class="flex size-full items-center justify-center p-5"
      data-testid="home-welcome"
    >
      <p class="pt-10 text-center text-base leading-normal">
        Save time and improve patient outcomes with our AI tool for
        psychologists. Mental Note will write your notes in the SOAP format or
        any other format you wish (DAP, PIRP, SIRP, GIRP, BIRP, PIE). Be more
        efficient and effective with MentalNote.
      </p>
    </div>
  </div>

  <div class="ml-3 flex flex-col">
    <span
      *ngIf="isAuthenticated && !isManagePlanVisible"
      data-testId="greeting"
    >
      {{ greeting$ | async }}
    </span>
  </div>
  <div class="px-4">
    <gentext-business-plan-offer></gentext-business-plan-offer>
  </div>
  <div class="grow overflow-y-auto" id="chat-scroll-container">
    <router-outlet></router-outlet>
  </div>
</div>
