import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChatComponent } from '@gentext/chat-ui';
import { ChatUiService } from '../chat-ui.service';

@Component({
  selector: 'gentext-chat-app',
  standalone: true,
  imports: [CommonModule, ChatComponent],
  templateUrl: './chat-app.component.html',
  styleUrl: './chat-app.component.css',
})
export class ChatAppComponent {
  clearMessages$ = this.chatService.clearMessages$;
  chatSuggestions = [
    {
      text: 'Patient reports persistent feelings of sadness, lack of interest in previously enjoyed activities, and trouble sleeping for the past two months.',
    },
  ];
  constructor(
    private router: Router,
    private chatService: ChatUiService,
  ) {}
  managePlan(showManagePlan: boolean) {
    if (showManagePlan) {
      this.router.navigate(['/manage-plan']);
    } else {
      this.router.navigate(['/chat']);
    }
  }
}
